:root {
  --white-color: #FFFFFF;
  --black-color: #000000;

  --dark-grey: #6E6E6E;
  --grey-200: #B7B7B7;
  --grey-300: #9A9A9A;
  --grey-400: #7D7D7D;
  --grey: #D4D4D4;
  --bright-grey: #F1F1F1;
  --brighter-grey: #F8F8F8;

  --primary: #FFED00;
  --secondary: #238C96;
  --secondary-100: #BDDDE0;
  --secondary-200: #91C6CB;

  --semantic-error: #E2021A;
  --semantic-warning: #F5802A;
  --semantic-success: #4AAD40;
  --semantic-info: #007BFF;

  /* TODO: These colors are not in the new colors definitions. */
  /* We need to clarify with the designers */
  --celadon-green-color: #238C96;
  --light-celadon-green-color: #BDDCDF;
  --primary-hover: #F2E100;
  --focus-border: #238C96;

  --bright-blue: #64D9FF;
  --green: #00E200;
  --gray-85: #D9D9D9;

  --green-100: #DBEFD9;
  --karcher-yellow-100: #FFFBCC;
  --red-100: #F9CCD1;
  --blue-100: #CCE5FF;
  --blue-link: #1677FF;
  --gray-100: #E5E5E5;
}
