
.icon-a_001_information:before { content: '\e800'; } /* '' */
.icon-a_002_information_circle:before { content: '\e801'; } /* '' */
.icon-a_003_information_sign:before { content: '\e802'; } /* '' */
.icon-a_004_arrow_left:before { content: '\e803'; } /* '' */
.icon-a_005_arrow_right:before { content: '\e804'; } /* '' */
.icon-a_006_arrow_up:before { content: '\e805'; } /* '' */
.icon-a_007_arrow_down:before { content: '\e806'; } /* '' */
.icon-a_008_arrow_left_circle:before { content: '\e807'; } /* '' */
.icon-a_009_arrow_right_circle:before { content: '\e808'; } /* '' */
.icon-a_010_arrow_up_circle:before { content: '\e809'; } /* '' */
.icon-a_011_arrow_down_circle:before { content: '\e80a'; } /* '' */
.icon-a_012_arrow_left_sign:before { content: '\e80b'; } /* '' */
.icon-a_013_arrow_right_sign:before { content: '\e80c'; } /* '' */
.icon-a_014_arrow_up_sign:before { content: '\e80d'; } /* '' */
.icon-a_015_arrow_down_sign:before { content: '\e80e'; } /* '' */
.icon-a_016_done:before { content: '\e80f'; } /* '' */
.icon-a_018_done_sign:before { content: '\e810'; } /* '' */
.icon-a_017_done_circle:before { content: '\e811'; } /* '' */
.icon-a_019_exclamation:before { content: '\e812'; } /* '' */
.icon-a_020_exclamation_circle:before { content: '\e813'; } /* '' */
.icon-a_021_exclamation_sign:before { content: '\e814'; } /* '' */
.icon-a_022_close:before { content: '\e815'; } /* '' */
.icon-a_023_close_circle:before { content: '\e816'; } /* '' */
.icon-a_024_close_sign:before { content: '\e817'; } /* '' */
.icon-a_025_question:before { content: '\e818'; } /* '' */
.icon-a_027_question_sign:before { content: '\e819'; } /* '' */
.icon-a_026_question_circle:before { content: '\e81a'; } /* '' */
.icon-a_028_add:before { content: '\e81b'; } /* '' */
.icon-a_029_add_circle:before { content: '\e81c'; } /* '' */
.icon-a_030_add_sign:before { content: '\e81d'; } /* '' */
.icon-a_031_off_canvas_menu:before { content: '\e81e'; } /* '' */
.icon-a_032_more:before { content: '\e81f'; } /* '' */
.icon-a_033_edit:before { content: '\e820'; } /* '' */
.icon-a_034_list:before { content: '\e821'; } /* '' */
.icon-a_035_home_line:before { content: '\e822'; } /* '' */
.icon-a_036_home_filled:before { content: '\e823'; } /* '' */
.icon-a_037_visible_line:before { content: '\e824'; } /* '' */
.icon-a_038_visible_filled:before { content: '\e825'; } /* '' */
.icon-a_039_full_screen:before { content: '\e826'; } /* '' */
.icon-a_040_fit_to_size:before { content: '\e827'; } /* '' */
.icon-a_041_change_assignment:before { content: '\e828'; } /* '' */
.icon-a_043_update:before { content: '\e829'; } /* '' */
.icon-a_042_reload:before { content: '\e82a'; } /* '' */
.icon-a_044_reset:before { content: '\e82b'; } /* '' */
.icon-a_045_link_line:before { content: '\e82c'; } /* '' */
.icon-a_046_link_filled:before { content: '\e82d'; } /* '' */
.icon-a_047_share:before { content: '\e82e'; } /* '' */
.icon-a_049_search_filled:before { content: '\e82f'; } /* '' */
.icon-a_048_search_line:before { content: '\e830'; } /* '' */
.icon-a_050_delete_line:before { content: '\e831'; } /* '' */
.icon-a_051_delete_filled:before { content: '\e832'; } /* '' */
.icon-a_052_settings_line:before { content: '\e833'; } /* '' */
.icon-a_053_settings_filled:before { content: '\e834'; } /* '' */
.icon-a_054_location_line:before { content: '\e835'; } /* '' */
.icon-a_055_location_filled:before { content: '\e836'; } /* '' */
.icon-a_056_smartphone_line:before { content: '\e837'; } /* '' */
.icon-a_057_smartphone_filled:before { content: '\e838'; } /* '' */
.icon-a_058_duration_line:before { content: '\e839'; } /* '' */
.icon-a_059_duration_filled:before { content: '\e83a'; } /* '' */
.icon-a_060_time_line:before { content: '\e83b'; } /* '' */
.icon-a_061_time_filled:before { content: '\e83c'; } /* '' */
.icon-a_062_user_profile_line:before { content: '\e83d'; } /* '' */
.icon-a_063_user_profile_filled:before { content: '\e83e'; } /* '' */
.icon-a_064_calendar_line:before { content: '\e83f'; } /* '' */
.icon-a_065_calendar_filled:before { content: '\e840'; } /* '' */
.icon-a_066_chat_line:before { content: '\e841'; } /* '' */
.icon-a_067_chat_filled:before { content: '\e842'; } /* '' */
.icon-a_068_feedback_line:before { content: '\e843'; } /* '' */
.icon-a_069_feedback_filled:before { content: '\e844'; } /* '' */
.icon-a_070_notification_line:before { content: '\e845'; } /* '' */
.icon-a_071_notification_filled:before { content: '\e846'; } /* '' */
.icon-a_072_notification_for_badge_line:before { content: '\e847'; } /* '' */
.icon-a_073_notification_for_badge_filled:before { content: '\e848'; } /* '' */
.icon-a_074_badge_notification:before { content: '\e849'; } /* '' */
.icon-a_075_sound_line:before { content: '\e84a'; } /* '' */
.icon-a_076_sound_filled:before { content: '\e84b'; } /* '' */
.icon-a_077_locked_line:before { content: '\e84c'; } /* '' */
.icon-a_078_locked_filled:before { content: '\e84d'; } /* '' */
.icon-a_079_unlocked_line:before { content: '\e84e'; } /* '' */
.icon-a_080_unlocked_filled:before { content: '\e84f'; } /* '' */
.icon-a_081_save_line:before { content: '\e850'; } /* '' */
.icon-a_082_save_filled:before { content: '\e851'; } /* '' */
.icon-a_083_rename:before { content: '\e852'; } /* '' */
.icon-a_084_package_line:before { content: '\e853'; } /* '' */
.icon-a_085_package_filled:before { content: '\e854'; } /* '' */
.icon-a_086_package_open_line:before { content: '\e855'; } /* '' */
.icon-a_087_package_open_filled:before { content: '\e856'; } /* '' */
.icon-a_088_wlan:before { content: '\e857'; } /* '' */
.icon-a_089_home_wlan_line:before { content: '\e858'; } /* '' */
.icon-a_090_home_wlan_filled:before { content: '\e859'; } /* '' */
.icon-b_091_battery_full_line:before { content: '\e85a'; } /* '' */
.icon-b_092_battery_full_filled:before { content: '\e85b'; } /* '' */
.icon-b_093_battery_two_third_line:before { content: '\e85c'; } /* '' */
.icon-b_094_battery_two_third_filled:before { content: '\e85d'; } /* '' */
.icon-b_095_battery_one_third_line:before { content: '\e85e'; } /* '' */
.icon-b_096_battery_one_third_filled:before { content: '\e85f'; } /* '' */
.icon-b_097_battery_empty_line:before { content: '\e860'; } /* '' */
.icon-b_098_battery_empty_filled:before { content: '\e861'; } /* '' */
.icon-b_099_battery_charging_line:before { content: '\e862'; } /* '' */
.icon-b_100_battery_charging_filled:before { content: '\e863'; } /* '' */
.icon-b_101_power_supply_line:before { content: '\e864'; } /* '' */
.icon-b_102_power_supply_filled:before { content: '\e865'; } /* '' */
.icon-b_103_limit:before { content: '\e866'; } /* '' */
.icon-b_104_humidity_line:before { content: '\e867'; } /* '' */
.icon-b_105_humidity_filled:before { content: '\e868'; } /* '' */
.icon-b_106_humidity_2_line:before { content: '\e869'; } /* '' */
.icon-b_107_humidity_2_filled:before { content: '\e86a'; } /* '' */
.icon-b_108_water_supply_line:before { content: '\e86b'; } /* '' */
.icon-b_109_water_supply_filled:before { content: '\e86c'; } /* '' */
.icon-b_110_water_supply_on_line:before { content: '\e86d'; } /* '' */
.icon-b_111_water_supply_on_filled:before { content: '\e86e'; } /* '' */
.icon-b_112_lightning_line:before { content: '\e86f'; } /* '' */
.icon-b_113_lightning_filled:before { content: '\e870'; } /* '' */
.icon-b_114_cloud_line:before { content: '\e871'; } /* '' */
.icon-b_115_cloud_filled:before { content: '\e872'; } /* '' */
.icon-b_116_rainy_line:before { content: '\e873'; } /* '' */
.icon-b_117_rainy_filled:before { content: '\e874'; } /* '' */
.icon-b_118_snowy_line:before { content: '\e875'; } /* '' */
.icon-b_119_snowy_filled:before { content: '\e876'; } /* '' */
.icon-b_120_thunderstorm_line:before { content: '\e877'; } /* '' */
.icon-b_121_thunderstorm_filled:before { content: '\e878'; } /* '' */
.icon-b_122_windy_line:before { content: '\e879'; } /* '' */
.icon-b_123_windy_filled:before { content: '\e87a'; } /* '' */
.icon-b_124_foggy_line:before { content: '\e87b'; } /* '' */
.icon-b_125_foggy_filled:before { content: '\e87c'; } /* '' */
.icon-b_126_frost_line:before { content: '\e87d'; } /* '' */
.icon-b_127_frost_filled:before { content: '\e87e'; } /* '' */
.icon-b_128_sunny_line:before { content: '\e87f'; } /* '' */
.icon-b_129_sunny_filled:before { content: '\e880'; } /* '' */
.icon-b_130_sundown_line:before { content: '\e881'; } /* '' */
.icon-b_131_sunrise_line:before { content: '\e882'; } /* '' */
.icon-b_132_spinner_line:before { content: '\e883'; } /* '' */
.icon-b_133_add_user_line:before { content: '\e886'; } /* '' */
.icon-b_134_add_user_filled:before { content: '\e887'; } /* '' */
.icon-c_220_export:before { content: '\e8db'; } /* '' */
.icon-c_224_remote_control_line:before { content: '\e8dc'; } /* '' */
.icon-c_228_basket_line:before { content: '\e8dd'; } /* '' */
.icon-c_232_plus_line:before { content: '\e8de'; } /* '' */
.icon-c_229_ready_line:before { content: '\e8df'; } /* '' */
.icon-c_221_hard_jet_line:before { content: '\e8e0'; } /* '' */
.icon-c_231_break_line:before { content: '\e8e1'; } /* '' */
.icon-c_226_half_window_clean_line:before { content: '\e8e2'; } /* '' */
.icon-c_225_full_window_clean_line:before { content: '\e8e3'; } /* '' */
.icon-c_230_boost_not_available_line:before { content: '\e8e4'; } /* '' */
.icon-c_227_camera_line:before { content: '\e8e5'; } /* '' */
.icon-c_233_minus_line:before { content: '\e8e6'; } /* '' */
.icon-c_223_flat_jet_line:before { content: '\e8e7'; } /* '' */
.icon-c_222_mix_soap_jet_line:before { content: '\e8e8'; } /* '' */
.icon-b_135_service_line:before { content: '\e912'; } /* '' */
.icon-b_136_service_filled:before { content: '\e913'; } /* '' */
.icon-b_137_logout_line:before { content: '\e914'; } /* '' */
.icon-b_138_logout_filled:before { content: '\e915'; } /* '' */
.icon-b_139_machine_line:before { content: '\e916'; } /* '' */
.icon-b_140_machine_filled:before { content: '\e917'; } /* '' */
.icon-b_141_paragraph:before { content: '\e918'; } /* '' */
.icon-b_142_paragraph_circle:before { content: '\e919'; } /* '' */
.icon-b_143_paragraph_sign:before { content: '\e91a'; } /* '' */
.icon-b_144_wlan_three_quarter:before { content: '\e91b'; } /* '' */
.icon-b_145_wlan_half:before { content: '\e91c'; } /* '' */
.icon-b_146_wlan_one_quarter:before { content: '\e91d'; } /* '' */
.icon-b_147_minimize_screen:before { content: '\e920'; } /* '' */
.icon-b_148_dot_for_graph:before { content: '\e921'; } /* '' */
.icon-b_149_update:before { content: '\e922'; } /* '' */
.icon-b_151_umbrella_filled:before { content: '\e923'; } /* '' */
.icon-b_152_water:before { content: '\e924'; } /* '' */
.icon-b_150_umbrella:before { content: '\e925'; } /* '' */
.icon-b_153_moon_crescent:before { content: '\e926'; } /* '' */
.icon-b_154_moon_crescent_filled:before { content: '\e927'; } /* '' */
.icon-b_155_moon_full:before { content: '\e928'; } /* '' */
.icon-b_156_moon_full_flled:before { content: '\e929'; } /* '' */
.icon-b_157_duration:before { content: '\e930'; } /* '' */
.icon-b_158_duration_filled:before { content: '\e931'; } /* '' */
.icon-b_159_clock_quarter:before { content: '\e932'; } /* '' */
.icon-b_160_clock_quarter_filled:before { content: '\e933'; } /* '' */
.icon-b_161_watering_can:before { content: '\e934'; } /* '' */
.icon-b_162_watering_can_filled:before { content: '\e935'; } /* '' */
.icon-b_163_watering_can_water:before { content: '\e936'; } /* '' */
.icon-b_164_watering_can_water_filled:before { content: '\e937'; } /* '' */
.icon-b_165_eco:before { content: '\e938'; } /* '' */
.icon-b_166_eco_filled:before { content: '\e939'; } /* '' */
.icon-b_167_leaves:before { content: '\e940'; } /* '' */
.icon-b_168_smart_home_circle:before { content: '\e941'; } /* '' */
.icon-b_169_smart_home_circle_filled:before { content: '\e942'; } /* '' */
.icon-b_170_bluetooth:before { content: '\e943'; } /* '' */
.icon-b_171_bluetooth_filled:before { content: '\e944'; } /* '' */
.icon-b_172_bluetooth_text:before { content: '\e945'; } /* '' */
.icon-b_173_starting_point:before { content: '\e946'; } /* '' */
.icon-b_174_starting_point_filled:before { content: '\e947'; } /* '' */
.icon-b_175_footprint:before { content: '\e948'; } /* '' */
.icon-b_176_footprint_filled:before { content: '\e949'; } /* '' */
.icon-b_177_full_screen_filled:before { content: '\e950'; } /* '' */
.icon-b_178_ventilation:before { content: '\e951'; } /* '' */
.icon-b_179_ventilation_filled:before { content: '\e952'; } /* '' */
.icon-b_180_ventialtion_simple:before { content: '\e953'; } /* '' */
.icon-b_181_rotation_simple:before { content: '\e954'; } /* '' */
.icon-b_182_rotation_simple_filled:before { content: '\e955'; } /* '' */
.icon-b_183_rotation_boost:before { content: '\e956'; } /* '' */
.icon-b_184_rotation_boost_filled:before { content: '\e957'; } /* '' */
.icon-b_185_magnifier:before { content: '\e958'; } /* '' */
.icon-b_186_magnifier_filled:before { content: '\e959'; } /* '' */
.icon-b_187_steam:before { content: '\e960'; } /* '' */
.icon-b_188_steam_filled:before { content: '\e961'; } /* '' */
.icon-b_189_wind_direction:before { content: '\e962'; } /* '' */
.icon-b_190_wind_direction_filled:before { content: '\e963'; } /* '' */
.icon-c_191_smart_mode_filled:before { content: '\e994'; } /* '' */
.icon-c_192_smart_mode_line:before { content: '\e995'; } /* '' */
.icon-c_193_smart_mode_circle_filled:before { content: '\e996'; } /* '' */
.icon-c_194_smart_mode_circle_line:before { content: '\e997'; } /* '' */
.icon-c_195_calendar_sad_empty:before { content: '\e998'; } /* '' */
.icon-c_196_calendar_happy_filled:before { content: '\e999'; } /* '' */
.icon-c_197_relaxed_user_filled:before { content: '\e99a'; } /* '' */
.icon-c_198_relaxed_user_line:before { content: '\e99b'; } /* '' */
.icon-c_199_world_europe_filled:before { content: '\e99c'; } /* '' */
.icon-c_200_world_europe_line:before { content: '\e99d'; } /* '' */
.icon-c_201_world_outline:before { content: '\e99e'; } /* '' */
.icon-c_202_world_europe_content:before { content: '\e99f'; } /* '' */
.icon-c_203_world_america_line:before { content: '\e9a0'; } /* '' */
.icon-c_204_world_america_filled:before { content: '\e9a1'; } /* '' */
.icon-c_205_world_america_content:before { content: '\e9a2'; } /* '' */
.icon-c_206_robot_filled:before { content: '\e9a3'; } /* '' */
.icon-c_207_robot_line:before { content: '\e9a4'; } /* '' */
.icon-c_208_robot_circle_filled:before { content: '\e9a5'; } /* '' */
.icon-c_209_robot_circle_line:before { content: '\e9a6'; } /* '' */
.icon-c_210_robot_idea_filled:before { content: '\e9a7'; } /* '' */
.icon-c_211_robot_idea_line:before { content: '\e9a8'; } /* '' */
.icon-c_212_calendar_flag:before { content: '\e9a9'; } /* '' */
.icon-c_213_target_filled:before { content: '\e9aa'; } /* '' */
.icon-c_214_target_line:before { content: '\e9ab'; } /* '' */
.icon-c_216_event_delete_all:before { content: '\e9b1'; } /* '' */
.icon-c_215_event_create_new:before { content: '\e9b2'; } /* '' */
.icon-c_217_blocker_create_new:before { content: '\e9b3'; } /* '' */
.icon-c_218_blocker_delete_all:before { content: '\e9b4'; } /* '' */
.icon-c_219_filter_products:before { content: '\e9b5'; } /* '' */
.icon-c_235_arrow_right:before { content: '\e9d4'; } /* '' */
.icon-c_234_arrow_left:before { content: '\e9d5'; } /* '' */